@import '../../../styles/colours.scss';

.drawer {
  .MuiDrawer-paper {
    min-height: 80px;
    background-color: $app_gold;
    padding: 15px 20px;
    overflow: hidden;
    z-index: 9;
  }

  .drawer-btn-close {
    position: absolute;
    top: -2px;
    right: 0;

    .MuiIconButton-label {
      justify-content: flex-end;
    }

    .MuiSvgIcon-root {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
  }

  &.error {
    .MuiDrawer-paper {
      background-color: $app_red;
      color: #fff;
    }
  }

  &.success {
    .MuiDrawer-paper {
      background-color: $app_green;
      color: #fff;
    }
  }

  .step-text {
    font-size: 12px;
  }

  &.drawer-tac {
    .MuiDrawer-paper {
      justify-content: center;
    }

    .tac--title {
      font-size: 15px;

      @media screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    .tac--description {
      padding: 0 10px;
      font-size: 12px;
      @media screen and (max-width: 768px) {
        padding: 0;
        margin-bottom: 20px;
      }

      .tac--tooltip {
        margin-left: 7px;
        height: 14px;
      }
    }

    .tac--timestamp {
      text-align: right;
      font-size: 12px;
      line-height: 1.2;
      justify-content: flex-end;
      align-items: flex-end;

      .MuiTypography-body1 {
        font-size: 12px;
      }

      .tac--resend-btn {
        font-weight: bold;
        background: none;
        border: 0;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }

      @media screen and (max-width: 768px) {
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 20px 0;

        br {
          display: none;
        }
      }
    }

    .tac--timer {
      margin: 0 10px;
    }

    .tac--input {
      padding: 10px;
      @media screen and (max-width: 768px) {
        padding: 0 0 15px 0;
      }
    }
  }

  .timer-secure2u {
    margin-top: 5px;
  }

  .box-tutorial-secure2u {
    display: flex;
    flex-direction: row;

    .step-2 {
      margin-left: 8px;
    }

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;

      .step-2 {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }
}
