// WHITE
$app_white: #ffffff;

// BLACK
$body_bg: #000;
$app_black: #171717;
$ms-fill-lower-border: #010101;
$range-slider-box-shadow-b: #0d0d0d;
$pedonalize-paragraph-color: #272727;

// GREY
$goals_stroke: #ddd;
$app_ash: #e7e7e7;
$app_grey1: #f7f7f7;
$app_grey2: #d7d7d7;
$app_grey3: #9d9d9d;
$app_grey4: #7d7d7d;
$app_grey5: #373737;
$app_grey6: #636363;
$app_grey7: #5c5c5c;
$app_grey8: #efefef;
$login_menu: #b9b9b9;
$tableHead: #e2e2e2;
$goal_message: #6c6c6c;
$dropzone-active-bg: #eee;
$transparent_btn: #e6e6e6;
$text-muted-color: #0c0a0a;
$dashboard-unselected: #9b9b9b;
$slider-runnable-track-bg: #979797;

// GREEN
$app_green: #389a6e;
$range-slider-bg: #40b07e;
$diagram_celery: #b2c753;
$diagram_shamrock: #2dc884;
$diagram_yellow_green: #c4d676;
$diagram_algae_green: #81dea6;
$spending_tooltip_background: #2da28d;

// BLUE
$diagram_viking: #4fc0db;
$ms-fill-upper: #3071a9;
$diagram_bismark: #416589;
$ms-fill-lower-bg: #2a6495;
$diagram_havelock_blue: #4a8fd0;

// GOLD / YELLOW / ORANGE

$app_gold: #ffc83d;
$app_legacy_gold: #e2a109;
$diagram_buttercup: #f5a623;
$btn-primary-bg-color: #fed136;
$goals_progress_stroke: #f5a623;

// RED
$app_red: #da4747;
$progressBackground: #dc6666;
