/* This stylesheet copied from the exiting M2U code based. It is generated by Transfonter (http://transfonter.org). */

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Bold.eot');
  src: url('../assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Lato-Bold.woff') format('woff'),
    url('../assets/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../assets/fonts/Lato-HairlineItalic.eot');
  src: url('../assets/fonts/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato-HairlineItalic.woff') format('woff'), url('../assets/fonts/Lato-HairlineItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Italic.eot');
  src: url('../assets/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Lato-Italic.woff') format('woff'),
    url('../assets/fonts/Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Black.eot');
  src: url('../assets/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Lato-Black.woff') format('woff'),
    url('../assets/fonts/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-LightItalic.eot');
  src: url('../assets/fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato-LightItalic.woff') format('woff'), url('../assets/fonts/Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Light.eot');
  src: url('../assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Lato-Light.woff') format('woff'),
    url('../assets/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Regular.eot');
  src: url('../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Lato-Regular.woff') format('woff'),
    url('../assets/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-BoldItalic.eot');
  src: url('../assets/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato-BoldItalic.woff') format('woff'), url('../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-BlackItalic.eot');
  src: url('../assets/fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lato-BlackItalic.woff') format('woff'), url('../assets/fonts/Lato-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../assets/fonts/Lato-Hairline.eot');
  src: url('../assets/fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/Lato-Hairline.woff') format('woff'),
    url('../assets/fonts/Lato-Hairline.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik Doodle Shadow';
  src: url('../assets/fonts/RubikDoodleShadow-Regular.eot');
  src: url('../assets/fonts/RubikDoodleShadow-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/RubikDoodleShadow-Regular.woff') format('woff'), url('../assets/fonts/RubikDoodleShadow-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
