@import '../../../styles/colours.scss';

%CARD_LINK {
  color: black;
  display: flex;
  font-size: 11px;
  font-weight: bold;
  margin-top: 1.5rem;
  align-items: center;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  svg {
    color: $app_gold;
    font-size: 15px;
    margin-left: 0.25rem;

    &:hover {
      animation: wobble-horizontal 1s ease-in-out;
      -moz-animation: wobble-horizontal 1s ease-in-out;
      -webkit-animation: wobble-horizontal 1s ease-in-out;
      -o-animation: wobble-horizontal 1s ease-in-out;
    }
  }
}

%CARD_CONTENT_DESC {
  font-size: 13px;
  color: #9d9d9d;
  overflow: hidden;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 100%;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  white-space: pre-wrap;
  font-family: 'Lato';
}

%CARD_TITLE {
  font-size: 14px;
  font-weight: bold;
  font-family: 'Lato';
  text-transform: capitalize;
}

.card {
  .bb-yellow {
    border-bottom: 2px solid $app_gold;
  }

  &.container--primary-media {
    height: 404px;
    max-width: 262.5px;
    font-family: 'Lato';

    .card--primary-media {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      // box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

      img {
        height: 142px;
      }

      .MuiCardContent-root {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #f7f7f7;

        .content--title {
          font-size: 40px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .content--desc {
          @extend %CARD_CONTENT_DESC;
        }

        .content--link {
          font-weight: bold;
          font-size: 13px;
          color: #9d9d9d;
          text-transform: uppercase;
        }
      }

      .MuiCardActions-root {
        padding: 1rem;

        button {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &.card--interactive-media {
    height: 430px;
    max-width: 290px;
    font-family: 'Lato';

    .card--container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;

      .MuiCardContent-root {
        height: 100%;
        display: flex;
        padding: 0;
        flex-direction: column;

        .content--wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .content--link {
            @extend %CARD_LINK;
          }
          .content--desc {
            @extend %CARD_CONTENT_DESC;
          }
        }

        .content--img {
          padding: 0;
          min-height: 204px;
          position: relative;
          overflow: hidden;

          h4 {
            top: 0;
            width: 100%;
            color: white;
            font-size: 20px;
            line-height: 1.2;
            font-family: 'Lato';
            position: absolute;
            text-transform: capitalize;
          }

          // Transitions effects when mouse over on image
          img {
            height: 100%;
            transition: all 0.5s ease-in-out;

            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }

      .MuiCardActions-root {
        padding: 1rem;

        button {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &.card--interactive-media2 {
    height: 360px;
    max-width: 450px;
    font-family: 'Lato';
    margin: 0.5rem;

    .card--container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      background-color: $app_grey1;

      .MuiCardContent-root {
        height: 100%;
        display: flex;
        padding: 0;
        flex-direction: column;

        .content--wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: $app_grey1;

          .content--link {
            @extend %CARD_LINK;
          }
          .content--desc {
            @extend %CARD_CONTENT_DESC;
          }
        }

        .content--img {
          padding: 0;
          min-height: 204px;
          position: relative;
          overflow: hidden;

          h4 {
            top: 0;
            width: 100%;
            color: white;
            font-size: 20px;
            line-height: 1.2;
            font-family: 'Lato';
            position: absolute;
            text-transform: capitalize;
          }

          // Transitions effects when mouse over on image
          img {
            height: 120%;
            transition: all 0.5s ease-in-out;

            &:hover {
              transform: scale(1.5);
            }
          }
        }
      }

      .MuiCardActions-root {
        padding: 1rem;

        button {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  &.card--panel {
    height: 100%;
    min-height: 158px;
    font-family: 'Lato';
    display: flex;
    position: relative;
    border-radius: 7px;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 1rem 0 rgba(128, 128, 128, 0.5);
    }

    .card--panel {
      height: 100%;
      position: relative;

      .content--img {
        height: 48px;
        width: 76px;
        margin-right: 11px;
      }

      .content--title {
        font-size: 14px;
        font-weight: 500;
        // text-transform: capitalize;
      }

      .content--desc {
        font-size: 13px;
        color: #9d9d9d;
      }

      .panel--footer {
        padding-left: 1rem;
        font-size: 1.25rem;
        color: #373737;
        background: #efefef;
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 60px;

        .footer--title {
          font-size: 13px;
          color: #9d9d9d;
          font-family: 'Lato';
          margin-bottom: 0.5rem;
          text-transform: capitalize;
        }

        .footer--subtitle {
          font-size: 18px;
          font-family: 'Lato';
          .green {
            color: $app_green;
          }
          .red {
            color: $app_red;
          }
          .black {
            color: black;
          }
          .grey {
            color: $app_grey3;
          }
        }

        .footer--subtitle-note {
          font-size: 13px;
          color: #9d9d9d;
          font-family: 'Lato';
          text-transform: capitalize;
          padding-top: 5px;
        }
      }
    }

    .card--menu {
      button {
        padding: 0;
      }
    }
  }

  &.card--img-cover {
    // height: 100%;
    min-height: 158px;
    max-width: 378px;
    position: relative;

    .content--container {
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      padding: 1.5rem;
      position: absolute;
      flex-direction: row;
      align-items: center;

      h4 {
        width: 90%;
        color: white;
        font-size: 2rem;
        font-family: 'Lato';
        text-transform: capitalize;
      }

      svg {
        color: white;
        font-size: 2.5rem;
      }
    }

    .MuiCardMedia-root {
      min-height: 158px;
    }
  }

  &.card--icon-label {
    padding: 2rem;
    border-radius: 0;
    background-color: #f5f5f5;

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      h6 {
        font-weight: bold;
        font-family: 'Lato';
        text-transform: capitalize;
      }

      .content--icon {
        svg {
          width: 3em;
          height: 3em;
        }
      }

      .content--link {
        @extend %CARD_LINK;
      }
    }
  }

  &.card--banner-info {
    max-width: 200px;
    border-radius: 1rem;
    box-shadow: none;
    background: none;

    .MuiCard-root {
      display: flex;
      flex-direction: column;
      border-radius: 1rem;

      .MuiCardContent-root {
        .card--title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .card--title {
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            font-family: 'Lato';
          }

          svg {
            color: $app_gold;
          }
        }

        .content--banner {
          height: 4px;
          width: calc(5rem - (2 * 1%));
          margin-top: 1rem;
          margin-bottom: 1rem;
          background-color: $app_gold;
        }

        .content--wrapper {
          .content--desc {
            font-size: 13px;
            color: #9d9d9d;
            overflow: hidden;
            letter-spacing: 0.3px;
            white-space: pre-wrap;
            font-family: 'Lato';
          }
        }
      }

      .MuiCardActions-root {
        padding-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  &.card--colour-icon {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.7rem;
    justify-content: space-between;

    .content--container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .circle {
        width: 2rem;
        height: 2rem;
        flex-basis: auto;
        border-radius: 100%;
        background-color: pink;
      }
    }

    .text {
      @extend %CARD_TITLE;
    }

    .caption {
      line-height: 1.2;
    }
  }

  &.card--left-icon-section {
    display: flex;
    flex-direction: row;
    border-radius: 0.7rem;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 1rem 0 rgba(128, 128, 128, 0.5);
    }

    .title {
      @extend %CARD_TITLE;
      display: flex;
      align-items: center;
    }

    .detail--left-section {
      background-color: #f7f7f7;
      list-style: none;
      @media screen and (max-width: 767px) {
        margin: 10px 0 10px 0;
        padding: 10px 0 10px 0;
      }
    }

    .content--left-section {
      min-height: 72px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      background-color: #f7f7f7;

      &.expandLess {
        padding-top: 16px;
        padding-bottom: 13px;
        justify-content: flex-start;
      }

      &.wealth--dashboard {
        width: 90px;
        border-top-left-radius: 0.7rem;
        border-bottom-left-radius: 0.7rem;
      }


      .section--circle {
        width: 54px;
        height: 54px;
        line-height: 4.5rem;
        border-radius: 50%;
        border: 3px solid #eee;
        display: flex;
        align-items: center;
        justify-content: center;

        .percentage {
          font-weight: 600;
          font-size: 0.8rem;
        }
      }

      .left-dropdown .MuiCollapse-wrapper {
        margin-top: 10px;
      }
    }

    .detail--right-section {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .offset-right{
      min-width: calc(16.66667% - 96px);
    }

    .content--right-section {
      width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .title .MuiSvgIcon-root {
        color: $app_grey3;
      }

      .subtitle {
        font-size: 9px;
        color: $app_grey3;
        font-weight: 600;
        font-family: 'Lato';
        margin-top: 0.5rem;
      }

      .right-dropdown .MuiCollapse-wrapper {
        margin-top: 38px;
      }
    }
  }

  &.card--panel-header {
    .MuiCardHeader-root {
      color: black;
      background-color: $app_grey1;
      flex-wrap: wrap;

      &.header--red {
        color: white;
        background-color: $app_red;
      }

      &.header--green {
        color: white;
        background-color: $app_green;
      }

      span {
        font-size: 14px;
        font-family: 'Lato';
      }
      .MuiSvgIcon-root {
        font-size: 1rem;
        @media screen and (max-width: 345px) {
          display: none;
        }

        &.right--icon {
          margin: -1px 10px 0 0;
          color: $app_gold;
        }
      }
      .MuiCardHeader-action {
        margin: 0;

        @media screen and (max-width: 570px) {
          margin: 0 0 10px 0;
          order: -1;
          flex: 1 0 100%;
        }
      }
    }
  }

  &.card--panel-item {
    padding: 1rem;
    border-radius: 7px;

    .content--item {
      margin: 0 !important;

      .item--right {
        color: $app_black;
      }
    }
  }

  // Responsive customisation
  @media (max-width: 767px) {
    &.container--primary-media {
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
    }

    &.card--panel {
      width: 100%;
      max-width: 100%;
    }

    &.card--banner-info {
      max-width: 100%;
    }
  }
}
