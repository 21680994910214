@import 'styles/colours.scss';

.transaction {
  .MuiTypography-root {
    &.disabled {
      color: $app_grey4;
    }
  }

  .account-selector--container {
    .MuiSelect-select {
      background: $app_white;

      &.Mui-disabled {
        background: $app_grey2;
      }
    }

    .favourite-list {
      height: 35vh;
      margin-top: 1rem;

      &.overflow-favourite-list{
        width: calc(100% + 37px);
        padding-right: 30px;
        @media screen and (max-width: 991px) {
          width: calc(100% + 14px);
          padding-right: 7px;
        }
      }

      @media screen and (max-height: 700px) {
        height: calc(35vh + 5vh);
      }
      @media (max-width: 767px) {
        margin-top: 1.4rem;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 7px;
        margin-left: 1rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: $app_grey1; 
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $app_grey2;
        border-radius: 50px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $app_grey3;
      }

      mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 90%, rgba(0,0,0,0));

      .MuiTypography-root {
        &.MuiFormControlLabel-label {
          width: 100%;

          @media screen and (max-width: 768px) {
            .mobile-padding {
              padding: 0.5rem 0;
            }
          }

          .favourite-list--menu-btn {
            float: right;
            margin-right: 0;

            @media screen and (max-width: 768px) {
              margin: 1rem 0;
            }

            .MuiIconButton-root {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.confirmation--section {
  height: 100%;
  padding: 0 15px 100px 15px;

  @media screen and (max-width: 768px) {
    padding-bottom: 280px;
  }

  .debit--amount {
    .item--right {
      color: $app_red;
    }
  }

  .MuiCardHeader-action {
    .MuiIconButton-root {
      width: auto;
    }

    .status--message {
      margin: 0;
      font-size: 14px;
    }
  }

  .card--panel-header {
    .btn--favourite {
      background: $app_grey1;
      color: $app_black;
    }

    .MuiCardHeader-avatar {
      align-self: flex-start;
    }
  }
}

.textfield--password {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 7px;
    background-color: #fff;
    border: solid 0.1rem #d7d7d7;
    justify-content: space-between;

    input {
      padding: 0;
      border: none !important;
      margin: 0 1rem;
    }

    .btn {
      padding: 0;
      width: 15%;
    }

    img {
      max-width: 30%;
      height: auto;
    }

    .MuiIconButton-label {
      justify-content: center;
    }

    &.readonly {
      background-color: $app_grey2;
    }
}

.font-small {
  label {
    font-size: 12px !important;
  }
}

.select-padding {
  @media screen and (min-width: 768px) {
    padding-right: 0.5rem;
  }
}
