@import 'styles/colours.scss';

.common {
  &.calendar {
    .textfield--label {
      padding: 8px 15px;
      border-radius: 5px;

      .MuiIconButton-label {
        color: $app_black;

        svg {
          height: 20px;
        }
      }

      .MuiIconButton-root {
        padding: 0;
        width: auto;
      }

      input {
        color: $app_grey3;
      }

      input:read-only {
        background: $app_white;
      }

      &.disabled {
        background: $app_grey2;

        .left--label {
          color: $app_grey3;
        }

        input:read-only {
          background: $app_grey2;
        }
      }
    }
  }
}

.calendar--menu {
  .MuiMenu-paper {
    margin-top: 10px;
    height: 270px;
    overflow: hidden;
    background: $app_grey5;

    .MuiList-padding {
      padding: 0;
    }

    .DayPicker-Caption {
      padding: 0;
      margin-bottom: 0;

      button {
        background: none;
        border: 0;
        color: $app_white;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }

      .btn-picker {
        display: inline-flex;
        align-items: center;

        &:hover {
          color: $app_gold;
        }

        > span {
          padding-right: 5px;
        }
      }

      .MuiCollapse-container {
        margin-top: 5px;
      }

      .year-picker {
        overflow-y: scroll;
        max-height: 200px;
        width: 270px;

        .year {
          padding: 7px;

          &:hover,
          &.selected {
            background: $app_gold;
            color: $app_black;

            button {
              color: $app_black;
            }
          }
        }
      }

      .month-picker {
        overflow: hidden;
        height: 220px;
        align-items: center;
        padding: 0 0 0.5rem 0;

        .month {
          padding: 15px 0;
          &:hover,
          &.selected {
            background: $app_gold;
            color: $app_black;

            button {
              color: $app_black;
            }
          }
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: $app_grey7;
      }

      ::-webkit-scrollbar-thumb {
        background: $app_grey3;
        border-radius: 10px;
      }
    }

    .DayPicker-Caption.opened + .DayPicker-Weekdays,
    .DayPicker-Caption.opened ~ .DayPicker-Body {
      visibility: collapse;
    }

    .DayPicker {
      display: block;
      font-size: 14px;

      .DayPicker-Day {
        color: $app_white;
        padding: 8px;

        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background-color: $app_gold;
          color: $app_black;
          border-radius: 0;
        }
      }

      .DayPicker-Day--today {
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: 2px;
          transform: translate(-50%, -50%);
          content: '\A';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: $app_gold;
        }
      }

      .DayPicker-Day--disabled {
        color: $app_grey7;
      }

      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $app_gold;
        color: $app_black;
        border-radius: 0;
      }

      .DayPicker-NavBar {
        .DayPicker-NavButton {
          width: 2em;
        }

        .DayPicker-NavButton--prev {
          background-image: url(../../../assets/icons/chevron-left-yellow.svg);
        }

        .DayPicker-NavButton--next {
          background-image: url(../../../assets/icons/chevron-right-yellow.svg);
        }
      }

      &[class~="hideMonthNavbar"] {
        .DayPicker-NavBar {
          display: none;
        }
      }
    }
  }
}
