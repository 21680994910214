.transaction--modal {
  &.modal.modal-form {
    .MuiDialogTitle-root {
      padding: 2rem;

      .MuiTypography-root {
        padding-left: 0;
      }
    }

    .content--description {
      margin: 0;
    }
  }

  .content--description {
    text-align: left;
  }
}

.ql-toolbar.ql-snow {
  margin-left: 2px;
  background-color: #eeeeeb;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  margin-left: 2px;
  border-bottom: none;
}

.ql-editor {
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 50px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2;
  }
}

.title--text-editor {
  margin-left: 1.8rem;
}

#counter {
  border: 1px solid #ccc;
  border-width: 0px 1px 1px 1px;
  color: #aaa;
  text-align: right;
  margin-top: 41px;
  margin-right: 41px;
  margin-left: 2px;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

@media screen and (max-width: 769px) {
  .react-quill {
    margin: 5px 10px 0px 16px;
  }
  
  #counter {
    border: 1px solid #ccc;
    border-width: 0px 1px 1px 1px;
    color: #aaa;
    text-align: right;
    margin-top: 40px;
    width: 85.5%;
    margin-left: 18px;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
}
