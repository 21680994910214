@import '../../../styles/colours.scss';

.modal {
  .MuiDialog-paper {
    border-radius: 8px;
  }

  .MuiDialogTitle-root {
    display: flex;
    padding: 0;

    .close-btn {
      align-self: flex-end;

      .MuiIconButton-label {
        color: $app_white;
      }
    }
    @media (max-width: 767px) {
      .btn {
        width: initial;
      }
    }
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .MuiDialogActions-root {
    padding: 15px 30px 30px 30px;
    .MuiBox-root{
      display: flex;
      justify-content: space-between;
      width: 100%;
      &:not(.coachmark--modal) &{
        @media (max-width: 569px) {
          flex-wrap: wrap;
          .MuiGrid-container{
            justify-content: space-between;
            padding-top: 1rem;
            max-width: calc(100% + 1.5rem);
            width: calc(100% + 1.5rem);
            flex-basis: calc(100% + 2rem);
            .MuiGrid-root{
              width: 50%;
            }
          }
        }
        @media (max-width: 369px) {
          .MuiGrid-container{
            .MuiGrid-root{
              width: 100%;
            }
          }
        }
      }
      .form--radio-label{
        width: 100%;
      }
    }
  }


  &.modal-form {
    .MuiDialogTitle-root {
      justify-content: space-between;
      align-items: center;
      background-color: $body_bg;
      border-bottom: 3px solid $app_gold;
      padding: 15px;

      .MuiTypography-root {
        padding-left: 15px;
        font-size: 16px;
        color: $app_white;
      }
    }

    .content--description {
      font-size: 16px;
    }
  }

  &.modal-prompt {
    .MuiDialogTitle-root {
      padding: 20px;
    }
    .content--description {
      margin: 0;
      text-align: left;
    }
    .primary-btn {
      margin-left: 20px;

      @media screen and (max-width: 768px) {
        margin: 20px 0 0 0;
      }
    }
  }
}

.small-form-modal {
  .MuiDialog-paper {
    width: 360px;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @media screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
    }
  }

  .MuiDialogTitle-root {
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    height: 124px;

    .close-btn {
      padding: 0;

      .MuiIconButton-label {
        display: inline;
        color: $app_white;
        text-align: right;
      }
    }
    .profile-image {
      align-self: center;
    }
  }

  .MuiDialogContent-root {
    padding: 30px 30px 0 30px;

    @media screen and (max-width: 768px) {
      flex: none;
    }
  }

  .MuiDialogActions-root {
    padding: 1rem 30px 40px 30px;
  }
}

.modal--account-activated {
  .modal--btn {
    button {
      min-width: 180px;
    }
    .btn--left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
