.content--list {
  display: flex;
  padding-bottom: 0.5rem;
  flex-direction: column;
  justify-content: space-between;

  &:last-child {
    padding-bottom: 0.5rem;
  }

  &.casa-detail-header {
    padding-bottom: 5px !important;
  }

  .required {
    color: red;
  }
}

.content--item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    align-items: initial !important;
  }

  .item--left {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }

  .item--right {
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    word-wrap: break-word;
  }
}
