body {
  margin: 0;
  font-family: 'Lato', 'Calibri', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}

h1,
h2,
h3 {
  font-weight: normal;
}

// Margin
.mr3 {
  margin-right: 1rem !important;
}

.mh3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mr4 {
  margin-right: 2rem !important;
}

.mb4 {
  margin-bottom: 2rem !important;
}

.mt2 {
  margin-top: 0.5rem !important;
}

.mt3 {
  margin-top: 1rem !important;
}

.mt4 {
  margin-top: 2rem !important;
}

.mh4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mv4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

// Padding

.ph4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

// Display
.db {
  display: block !important;
}

// Responsive display
.hidden-xs {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.hidden-md {
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
}

.visible-s {
  @media screen and (min-width: 768px) {
    display: none !important;
  }

  // @media screen and (min-width: 480px) {
  //   display: none !important;
  // }
}

.visible-xs {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.visible-md-flex {
  display: none;

  @media screen and (max-width: 1199px) {
    display: flex;
  }
}

.button-modal-mobil-secure2u {
  @media screen and (max-height: 800px) {
    margin-bottom: 320px;
  }
  margin-bottom: 100px;
  width: 100%;
}

