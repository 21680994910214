// Animation for arrow to move horizontally
@keyframes wobble-horizontal {
  16.65% {
    transform: translateX(6px);
  }
  33.3% {
    transform: translateX(-5px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
